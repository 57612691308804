import React, { useEffect, useState } from "react";
import { Player } from "react-tuby";
import { Post, Caption } from "@/models";
import "./player.css";

function formatSubLabel(caption: Caption) {
  var label = caption.label;
  if (caption.type === "cp") label += " (official)";
  if (caption.type === "auto") label += " (auto)";
  if (caption.type === "fan") label += " (fan)";
  if (caption.fanName !== "") label += ` by ${caption.fanName}`;
  return label;
}

type VideoStatus = {
  status: string;
  percentDone?: number;
};

const VideoPlayer = ({
  post,
  thumbnail_url,
}: {
  post: Post;
  thumbnail_url: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [videoAvailability, setVideoAvailability] = useState(false);
  const [video_url, setVideoUrl] = useState(
    "https://vlive-11.vlivearchive.com/placeholder.mp4"
  );
  const [videoStatus, setVideoStatus] = useState<VideoStatus | null>(null);

  function etaAvailable(videoDurationSeconds: number): string {
    const FACTOR = 20;
    let secondsToProcess = Math.max(videoDurationSeconds / FACTOR, 40);
    if (secondsToProcess >= 60) {
      const minutes = Math.round(secondsToProcess / 60);
      return `${minutes} min`;
    } else {
      return `${Math.round(secondsToProcess)} sec`;
    }
  }

  useEffect(() => {
    const fetchVideoUrl = async () => {
      setLoading(true);
      if (post?.officialVideo?.videoSeq) {
        try {
          const url = `https://vlivearchive.com/api/download/${post.officialVideo.videoSeq}`;
          const response = await fetch(url);
          if (response.status === 404) {
            console.log("Video not found");
            setVideoAvailability(false);
            const url = `https://api.vlivearchive.com/video/${post.officialVideo.videoSeq}/status`;
            const response = await fetch(url);
            const data = await response.json();
            setVideoStatus(data);
          } else if (response.status === 200 || response.status === 302) {
            console.log("Video found");
            setVideoUrl(url);
            setVideoAvailability(true);
          }
        } catch (error) {
          console.error("Error fetching video URL:", error);
        }
      }
      setLoading(false);
    };

    fetchVideoUrl();
  }, [post]);

  async function requestVideo() {
    try {
      const response = await fetch(
        `https://api.vlivearchive.com/video/${post.officialVideo.videoSeq}/request`
      );
      const data = await response.json();
      if (data.status === "success") {
        console.log("Video requested");
        setVideoStatus({ status: "pending" });
        startPollingForStatus();
      }
    } catch (error) {
      console.error("Error requesting video:", error);
    }
  }

  function startPollingForStatus() {
    const intervalId = setInterval(async () => {
      if (post?.officialVideo?.videoSeq) {
        try {
          const url = `https://api.vlivearchive.com/video/${post.officialVideo.videoSeq}/status`;
          const response = await fetch(url);
          const data = await response.json();
          setVideoStatus(data);
          console.log(data);
          if (data.status === "available") {
            // setVideoAvailability(true);
            // setVideoUrl(
            //   `https://vlivearchive.com/api/download/${post.officialVideo.videoSeq}`
            // );
            clearInterval(intervalId);
            window.location.reload();
          }
        } catch (error) {
          console.error("Error fetching video status:", error);
        }
      }
    }, 3000);
  }

  var subtitles = post?.captions || [];

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
        <div className="w-full max-w-2xl bg-gray-300 animate-pulse h-64 mb-4"></div>
        <div className="w-full max-w-2xl bg-gray-300 animate-pulse h-8 mb-2"></div>
        <div className="w-full max-w-2xl bg-gray-300 animate-pulse h-8 mb-2"></div>
        <div className="w-full max-w-2xl bg-gray-300 animate-pulse h-8 mb-2"></div>
        <p className="mb-4 text-lg text-gray-700">Loading...</p>
      </div>
    );
  }

  if (videoAvailability) {
    return (
      <Player
        src={video_url}
        poster={thumbnail_url}
        subtitles={subtitles.map((e) => ({
          url: `/subtitles/${e.file_name}`,
          lang: e.language,
          language: formatSubLabel(e),
        }))}
      />
    );
  }

  if (videoStatus?.status === "available_to_request") {
    return (
      <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
        <p className="mb-4 text-lg text-gray-700">
          Press button below to play the video. Estimated loading time:{" "}
          {etaAvailable(post.officialVideo.playTime)}.
        </p>
        <button
          onClick={requestVideo}
          className="px-4 py-2 font-semibold text-white bg-blue-500 rounded hover:bg-blue-700"
        >
          Play Video
        </button>
      </div>
    );
  } else if (videoStatus?.status === "video_not_found") {
    return (
      <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
        <p className="mb-4 text-lg text-gray-700">
          This video does not exist in our database.
        </p>
      </div>
    );
  } else if (videoStatus?.status === "missing") {
    return (
      <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
        <p className="mb-4 text-lg text-gray-700">
          This video is missing from our archives and cannot be requested. If
          you have a copy of the video, please contact us at
          admin@vlivearchive.com.
        </p>
      </div>
    );
  } else if (
    videoStatus?.status === "pending" ||
    videoStatus?.status === "queued"
  ) {
    return (
      <div className="flex flex-col items-center justify-center h-full p-4 bg-gray-100">
        <div className="mb-4">
          <svg
            className="animate-spin h-5 w-5 text-gray-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
        <p className="mb-4 text-lg text-gray-700">Loading Video</p>
      </div>
    );
  } else if (videoStatus?.status === "in_progress") {
    return (
      <div className="flex flex-col items-center justify-center h-40 p-4 bg-gray-100">
        <p className="mb-4 text-lg text-gray-700">
          Processing video, please wait.
        </p>
        {videoStatus?.percentDone !== undefined && (
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full animate-pulse"
              style={{ width: `${videoStatus.percentDone}%` }}
            ></div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-40 p-4 bg-gray-100">
        <p className="mb-4 text-lg text-gray-700">
          There was an error fetching the video, and it is unknown if it is
          available to request. Please try again later.
        </p>
      </div>
    );
  }
};

export default VideoPlayer;
